import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import IndustryCard from "../components/BlogContent/IndustryCard";

const Blog = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner pageTitle="Industrije" />
      <IndustryCard />
      <Footer />
    </Layout>
  );
};

export default Blog;
