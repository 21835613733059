import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const query = graphql`
  {
    allStrapiIndustry {
      nodes {
        title
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

const BlogCard = () => {
  const data = useStaticQuery(query);
  const {
    allStrapiIndustry: { nodes: industries },
  } = data;
  return (
    <div className="projects-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          {industries.reverse().map((industry) => {
            const image = getImage(industry.image.localFile);
            return (
              <div className="col-lg-4 col-md-6">
                <Link to={`/industry/${industry.slug}`} className="link-btn">
                  <div className="single-projects-box">
                    <div className="image">
                      <GatsbyImage
                        image={image}
                        alt=""
                        formats={["AUTO", "WEBP", "AVIF"]}
                      />
                    </div>

                    <div className="content">
                      <h3>
                        <Link to={`/industry/${industry.slug}`}>
                          {industry.title}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
